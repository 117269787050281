<template>

  <div class="bStyle">

    <div id="d1" style="display:none;z-index: 1000;position: relative;">
      <ModalAlwaysShow
        tipo='error'
        :messageErr="internalizationConfCons.confConsErrTimeOutLabel"
        :detailErr="internalizationConfCons.confConsErrTimeOutMsg"
      />
    </div>

    <GreyContainer class="box1" style="margin-bottom: 25px;">
      <div class="text textbox1">{{internalizationConfCons.confConsInstMsg}}</div>
      <div class="title titlebox1">{{consentDetails.orgName}}</div>
    </GreyContainer>

    <div class="title" style="margin-top: 30px;margin-bottom: 20px; color: var(--confcons-back-title1-color);">{{internalizationConfCons.confConsNewTranLabel}}</div>

    <PanelWBorder 
      :titulo="internalizationConfCons.confConsPayLabel"
      :valor="consentDetails.pValue" 
      :pstyle="panel" 
      :ptstyle="panelTitle" 
      :ftype="fTypetmp"
      :ftype2="fTypetmp2" 
      :pcstyle="panelContent">
    
      <PanelRectangle
        :baserecstyle="baseRec"
        :recstyle="rec"
        style="display: var(--confcons-box2-up-rectangle-display)">
      </PanelRectangle>
      <hr v-if="!useRec" class="hrbox2" style="margin-bottom: 20px;">

      <div class="subtitle box2innertitle">{{internalizationConfCons.confConsDataBoxPayerLabel}}</div>
      <div class="text box2innertext weight">{{consentDetails.pDocumentRel}} {{consentDetails.pDocument}}</div>
      <div class="text box2innertext weight">{{consentDetails.pName}}</div>
      <div class="text box2innertext weight">{{internalizationConfCons.confConsDataBoxPayerAggLabel}} {{consentDetails.pAgg}}</div>
      
      <div class="text box2innertext weight p-d-flex p-jc-between">

        <div>{{consentDetails.pAccountType}}: {{consentDetails.pAccount}}</div>
        <div>
          <span v-if="accountNumber > 1">
            <Editbutton
              :rotulo="internalizationConfCons.confConsEditLabel"
              tipo="button"
              v-bind:width="16"
              v-bind:height="16"
              @botaoAtivado="editAccount(false)">
            </Editbutton>
          </span>
        </div>
        
      </div>

      <hr>

      <div class="subtitle box2innertitle">{{internalizationConfCons.confConsDataBoxBeneficiaryLabel}}</div>
      <div class="text box2innertext weight">{{internalizationConfCons.confConsDataBoxBeneficiaryCNPJLabel}} {{consentDetails.bCnpj}}</div>
      <div class="text box2innertext weight">{{consentDetails.bName}}</div>

      <template v-if="consentDetails.localInstrument == 'MANU'">
        <div class="text box2innertext weight">{{internalizationConfCons.confConsDataBoxBeneficiaryBankLabel}} {{consentDetails.bBankCode}}</div>
        <div class="text box2innertext weight">{{internalizationConfCons.confConsDataBoxBeneficiaryAggLabel}} {{consentDetails.bAgg}}</div>
        <div class="text box2innertext weight">{{consentDetails.bAccountType}}: {{consentDetails.bAccount}}</div>
      </template>

      <template v-if="consentDetails.localInstrument == 'DICT' || consentDetails.localInstrument == 'INIC'">
        <div class="text box2innertext weight">{{internalizationConfCons.confConsDataBoxPixLabel}} {{consentDetails.bPixValue}}</div>
      </template>

      <template v-if="consentDetails.localInstrument == 'QRDN'">
        <div class="text box2innertext weight">{{internalizationConfCons.confConsDataBoxQRDinLabel}}</div>
        <div class="text box2innertext weight">{{internalizationConfCons.confConsDataBoxPixLabel}} {{consentDetails.bPixValue}}</div>
      </template>

      <template v-if="consentDetails.localInstrument == 'QRES'">
        <div class="text box2innertext weight">{{internalizationConfCons.confConsDataBoxQREstLabel}}</div>
        <div class="text box2innertext weight">{{internalizationConfCons.confConsDataBoxPixLabel}} {{consentDetails.bPixValue}}</div>
      </template>
      
      <hr>

      <div class="subtitle box2innertitle">{{consentDetails.payLabel?internalizationConfCons.confConsDataBoxStartPayDateLabel:internalizationConfCons.confConsDataBoxSchedulePayDateLabel}}</div>
      <div class="text box2innertext weight">{{consentDetails.payData}}</div>
      
      <hr>

      <div class="subtitle box2innertitle">{{internalizationConfCons.confConsDataBoxPayFormLabel}}</div>
      <div class="text box2innertext weight">{{consentDetails.payForm}}</div>
      
      <hr>

      <template v-if="consentDetails.payFee != null && consentDetails.payFee != ''">
        <div class="subtitle box2innertitle">{{internalizationConfCons.confConsDataBoxPayFeeLabel}}</div>
        <div class="text box2innertext weight">{{consentDetails.payFee}}</div>
        <hr>
      </template>

      <div class="subtitle box2innertitle">{{internalizationConfCons.confConsDataBoxIdConsLabel}}</div>
      <div class="text box2innertext weight">{{consentDetails.idConsent}}</div>
      
    </PanelWBorder>

    <div v-if="consentDetails.multiple">
      <div class="title" style="margin-top: 10px; color: var(--confcons-back-title2-color)">{{internalizationConfCons.confConsMultMsg1}}</div>
      <div class="text" style="margin-bottom: 10px; color: var(--confcons-back-text2-color)">{{internalizationConfCons.confConsMultMsg2}}</div>

      <GreyContainer class="box3">
        <div class="title titlebox3" style="text-align: center">{{internalizationConfCons.confConsMultMsg3}}</div>
        <hr>
        <div class="text textbox3" style="text-align: center; margin-bottom: 15px;">{{internalizationConfCons.confConsMultMsg4}}</div>
        <div class="text textbox3" style="text-align: center;">{{internalizationConfCons.confConsMultMsg5}}</div>
        <div class="databox3" style="text-align: center; margin-top: 5px; margin-bottom: 5px">{{consentDetails.multDateTime}}</div>
        <div class="text textbox3" style="text-align: center;  margin-bottom: 15px;">{{internalizationConfCons.confConsMultMsg6}}</div>
        <div class="text textbox3" style="text-align: center;">{{internalizationConfCons.confConsMultMsg7}}</div>
      </GreyContainer>
    </div>
    
    <div style="margin-top:15px">

      <Mbutton
        :rotulo="consentDetails.payLabel?internalizationConfCons.confConsConfirmButtonLabel:internalizationConfCons.confConsConfirmScheduleButtonLabel"
        tipo="button"
        :confirmacao="false"
        @botaoAtivado="confirmShow()"
      />

      <Cbutton style="margin-top:15px"
        :rotulo="internalizationConfCons.confConsCancelButtonLabel"
        tipo="submit"
        :confirmacao="false"
        @botaoAtivado="cancelShow()"
      />

      <div class="space"></div>

    </div>

  </div>

  <BModal v-if="mfa" ref="mfaModal" type="iframe">
    <iframe id="iframeMFA" src="./mfa/defaultMFA.html"/>
  </BModal>

  <ExceptionModal
    ref="exceptionNotAwaitingAuthorization"
    tipo='withAccLink'
    messageErr=""
    :detailErr="internalizationConfCons.confConsErrConsNotAwaitingMsg"
    :detailErr2="internalizationConfCons.confConsErrConsNotAwaitingLinkLabel"
  />

  <ExceptionModal
    ref="exceptionCommunicationFail"
    tipo='error'
    :messageErr="internalizationConfCons.confConsErrCommunicationFailLabel"
    :detailErr="internalizationConfCons.confConsErrCommunicationFailMsg"
  />

  <ExceptionModal
    ref="fetchtimeoutwarning"
    tipo='error'
    :messageErr="internalizationConfCons.confConsErrFetchTimeOutLabel"
    :detailErr="internalizationConfCons.confConsErrFetchTimeOutMsg"
  />

  <ExceptionModalClean
    ref="cpfValidationError"
    tipo='error'
    :messageErr="internalizationConfCons.confConsErrCpfValidationLabel"
    :detailErr="internalizationConfCons.confConsErrCpfValidationMsg"
  />

  <ConfirmCancelModal
    ref="confirmBox"
    :isCancel="false"
    :header="consentDetails.payLabel?internalizationConfCons.confConsModalConfirmHeader:internalizationConfCons.confConsModalConfirmScheduleHeader"
    :description="consentDetails.payLabel?internalizationConfCons.confConsModalConfirmDescription:internalizationConfCons.confConsModalConfirmScheduleDescription"
    :fstButtonTxt="consentDetails.payLabel?internalizationConfCons.confConsModalConfirmFstButtonLabel:internalizationConfCons.confConsModalConfirmFstScheduleButtonLabel"
    :sndButtonTxt="internalizationConfCons.confConsModalConfirmSndButtonLabel"
    @action="confirmConsent()"
  />

  <ConfirmCancelModal
    ref="cancelBox"
    :isCancel="true"
    :header="internalizationConfCons.confConsModalCancelHeader"
    :description="internalizationConfCons.confConsModalCancelDescription"
    :boldString="internalizationConfCons.confConsModalCancelBoldInsideDescription"
    :fstButtonTxt="internalizationConfCons.confConsModalCancelFstButtonLabel"
    :sndButtonTxt="internalizationConfCons.confConsModalCancelSndButtonLabel"
    @action="cancelConsent()"
  />

</template>

<script>
Date.prototype.addHours = function(h){
  this.setHours(this.getHours()+h);
  return this;
};

import { getWithExpiry, setLogoIniciadora, getInternalization, endSess,MaskCpfCnpjBlur } from '@/util/util.js';

var payload = null;

/* Request time out controllers */
const FETCH_TIMEOUT = 30000;
// var didTimeOut = false;
/* */

const formCurrency = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
  maximumFractionDigits: 4
});

async function getconsentDetails(consent_id) {

  var requestOptionsforDetails = {
    method: 'GET',
    redirect: 'follow'
  };

  var response;
  var consentD;
  var checkCT21 = sessionStorage.getItem("TIMEOUT_SIMULATION_CT21");
  
  if(checkCT21 === "true") {
    //console.log("esperando...");
    await new Promise(resolve => setTimeout(resolve, FETCH_TIMEOUT));
    //console.log("esperado.");
    response = await fetch(process.env.VUE_APP_CONSENTS+'/consent/consent-detail/' + consent_id, requestOptionsforDetails);
    consentD = await response.json();
  } 
  else {
    response = await fetch(process.env.VUE_APP_CONSENTS+'/consent/consent-detail/' + consent_id, requestOptionsforDetails);
    consentD = await response.json();
  }

  return consentD;
}

// async function getConsentToken() {

//   var requestOptions = {
//     method: 'GET',
//     redirect: 'follow'
//   };

//   let response = await fetch(process.env.VUE_APP_CONSENTS+'/consent/consent-token', requestOptions);
//   let token = await response.json();

//   return token;
// }

/*
function patchAccount(userId, consentId){

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "psuIdentifiers": {
      "userId": userId
    }
  });

  var requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  const promiseConfirm = new Promise( function(resolve, reject){

    fetch(process.env.VUE_APP_CONSENTS+"/consent/set-account/" + consentId, requestOptions)
    .then(function(response) {
      
      if (!response.ok) {
        throw 'fetch err ' + response.status;
      }
      //console.log(response);

    })
    .catch(function(err) {
      reject(err);
    });
  })
  .catch(function(err) {
    console.log(err);
  });
}
*/

/*
async function patchAccount(userId, consentId) {

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "psuIdentifiers": {
      "userId": userId
    }
  });

  var requestOptions = {
    method: 'PATCH',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  //console.log(requestOptions);
  //console.log(process.env.VUE_APP_CONSENTS+"/consent/set-account/" + consentId);

  let response = await fetch(process.env.VUE_APP_CONSENTS+"/consent/set-account/" + consentId, requestOptions);

  return response;
}
*/

var isEmpty = function(obj){

  if (obj == undefined || obj == null || obj == '')
    return true;
  
  if (typeof obj === 'string')
    return obj.replace(/\s/g, '').length < 1;

  return false;
}

/* eslint-disable */
export default {
  data() {
    return {

      enablemfa: false,

      /* da aplicação */
      mfa: false,
      accountNumber: 0,
      onLine:true,
      editRoute: 'SelectAccount',
      redirectRoute: 'Redirect',
      accountTypes: {
        'CACC' : 'Conta corrente',
        'SLRY' : 'Conta salário',
        'SVGS' : 'Conta poupança',
        'TRAN' : 'Conta de pagamento pré-paga'
      },
      /* */

      /* dados */
      consentDetails : {
      },
      /* */

      /* stilos de componentes personalizados */
      baseRec:{
      },
      rec:{
        'background-color': 'var(--confcons-box2-up-hrrectangle-color)'
      },
      panel:{
        'border': '1px solid var(--confcons-box2-border-color)',
        'background-color': 'var(--confcons-box2-up-background-color)',
        'box-shadow': 'var(--confcons-box2-shadow)',
        'filter': 'var(--confcons-box2-filter)'
      },
      panelTitle:{
      },
      panelContent:{
        'background': 'var(--confcons-box2-down-background-color)',
        'border': '3px solid var(--confcons-box2-border-color)',
        'padding': '0px 30px 30px 40px'
      },
      fTypetmp:{
        'color': 'var(--confcons-box2-up-amount-title-text-color)'
      },
      fTypetmp2:{
        'color': 'var(--confcons-box2-up-amount-title-value-color)'
      }
      /* */
    }
  },
  methods: {
    confirmShow(){
      this.verifyAccount();
      this.$refs.confirmBox.enable();
    },
    cancelShow(){
      this.$refs.cancelBox.enable();
    },

    editAccount(showNoSelectModal){
      //console.log('showNoSelect ' + showNoSelectModal);
      this.$router.push({
          name: this.editRoute,
          params: {showNoSelectModal: showNoSelectModal}
        });
    },
    verifyAccount(){
      if(isEmpty(this.consentDetails.accountId) || isEmpty(this.consentDetails.pAccount) || isEmpty(this.consentDetails.pAccountType) || isEmpty(this.consentDetails.pAgg)){
        this.editAccount(true);
      }
    },
    convertUtcDateToString(date, locale, isDateTime){
      const tmp = new Date(date)
      const newDate = new Date(date).addHours(3)
      return isDateTime ? tmp.toLocaleString(locale) : newDate.toLocaleDateString(locale)
    },
    
    maskCpfCnpj(cpfCnpj, size) {
      return size == 11 
        ? `${MaskCpfCnpjBlur(cpfCnpj, size)}`
        : `${MaskCpfCnpjBlur(cpfCnpj, size)}`;
    },

    confirmConsent(){
      
      this.verifyAccount();
      
      if (this.enablemfa && this.consentDetails.mfa){
        this.$refs.confirmBox.disable();
        this.mfa = true;
      }
      else
        this.$router.push({ name: this.redirectRoute, params: { requestFor: 'confirm', accountId: this.consentDetails.accountId, creationDateTime: this.consentDetails.creationDateTime }});
    },
    cancelConsent(){

      this.verifyAccount();

      this.$router.push({ name: this.redirectRoute, params: { requestFor: 'cancel', accountId: this.consentDetails.accountId, creationDateTime: this.consentDetails.creationDateTime }});
    },

    checkOnline(){
      if(!navigator.onLine){
        endSess('dados');
        this.$refs.confirmBox.disable();
        this.$refs.cancelBox.disable();

        this.disableAllWarnings();
        this.$refs.exceptionCommunicationFail.enable();
      }
    },
    defTimeOut(){
      var timeout;
      function refresh(){

        clearTimeout(timeout)
        timeout = setTimeout(() => {
        
          var el = document.getElementById("d1");
          el.style.display = "flex";
          
        },  300000);
      }
      refresh();
      document.addEventListener('click', refresh);
    },
    disableAllWarnings(){

      this.$root.$refs.exceptionWarnigFetchingDataPay.disable();
      
      document.getElementById("d1").style.display = "none";
      
      this.$refs.fetchtimeoutwarning.disable();
      this.$refs.exceptionNotAwaitingAuthorization.disable();
      this.$refs.exceptionCommunicationFail.disable();
      this.$refs.cpfValidationError.disablenoredirect();
      
    }
  },
  async created(){

    this.internalizationConfCons = getInternalization();
    
  },
  async mounted(){

    window.onmessage = (e) => {
      
      /*
      if(document.getElementById("iframeMFA")!=null && e.data.requestFor == 'size'){

        //console.log('inside height and width');

        document.getElementById("iframeMFA").style.height = `${e.data.frameHeight}px`;
        document.getElementById("iframeMFA").style.width = `${e.data.frameWidth}px`;

      }
      else*/ if(document.getElementById("iframeMFA")!=null && e.data.requestFor == 'accepted'){

        if (e.data.mfaAccepted === true){
          this.$router.push({ name: this.redirectRoute, params: { requestFor: 'confirm', accountId: this.consentDetails.accountId, creationDateTime: this.consentDetails.creationDateTime }});
        }
        else {
          this.$router.go();
        }

      }
    }

    this.defTimeOut();
    this.$root.$refs.mainNavbar.hideGoBackButton();
    this.$root.$refs.loadingModal.enable();

    let timeout;

    function timeoutPromise() {
      return new Promise(resolve => {
        timeout = setTimeout(() => {
          resolve('Request timed out');
        }, FETCH_TIMEOUT);
      });
    }

    try {
      payload = JSON.parse(getWithExpiry('dados'));
      
      if(!payload){
        this.$root.$refs.loadingModal.disable();

        this.disableAllWarnings();
        this.$root.$refs.exceptionWarnigFetchingDataPay.enable();
      }
      else{
        
        setInterval(() => {
          this.checkOnline();
        }, 3000);

        //await patchAccount((this.$route.params.hasOwnProperty('data') ? this.$route.params.data : payload.account_id), payload.consent_id);

        let consentDResponse = await Promise.race([timeoutPromise(),getconsentDetails(payload.consent_id)]);
        this.consentDetails.localInstrument = consentDResponse.data.consentBody.data.payment.details.localInstrument.toUpperCase();

        //console.log(payload);
        //console.log(consentDResponse);
        //console.log(this.consentDetails.localInstrument);

        if(consentDResponse == 'Request timed out'){
          throw 'Request timed out';
        }
        else if (consentDResponse.data.consentBody.data.status.toLowerCase().trim() != 'awaiting_authorisation'){
          throw 'invalid consent status';
        }
        else if (this.consentDetails.localInstrument != 'MANU' &&
          this.consentDetails.localInstrument != 'DICT' &&
          this.consentDetails.localInstrument != 'INIC' &&
          this.consentDetails.localInstrument != 'QRDN' &&
          this.consentDetails.localInstrument != 'QRES'){
            throw 'invalid localInstrument';
        }
        else{
          clearTimeout(timeout);
          
          if(isEmpty(consentDResponse.ssaDetail) || isEmpty(consentDResponse.ssaDetail.software_logo_uri))
            setLogoIniciadora(null);
          else
            setLogoIniciadora(consentDResponse.ssaDetail.software_logo_uri);
        }

        this.consentDetails.accountId = (this.$route.params.hasOwnProperty('data') ? this.$route.params.data : payload.account_id);

        payload.accounts.forEach(element => {
          
          if(!isEmpty(element.account_id) && !isEmpty(element.account_number) && !isEmpty(element.account_agency) && !isEmpty(element.account_type)){
            this.accountNumber++;
            //console.log('att accountNumber to' + this.accountNumber);
          }

          if(element.account_id == this.consentDetails.accountId){
            this.consentDetails.pName        = element.owner;
            this.consentDetails.pAccount     = element.account_number;
            this.consentDetails.pAccountType = this.accountTypes[element.account_type];
            this.consentDetails.pAgg         = element.account_agency;
            this.consentDetails.multiple     = element.multi_approve;
            this.consentDetails.mfa          = element.mfa;
          }
        });

        this.verifyAccount();
        
        let userDocument = consentDResponse.data.consentBody.data.loggedUser.document.identification;

        if(userDocument != payload.document) {
          endSess('dados');
          this.$root.$refs.loadingModal.disable();
          window.scrollTo( screen.width/2, screen.height/2 );

          this.disableAllWarnings();
          this.$refs.cpfValidationError.enable();

          return;
        }

        this.consentDetails.idConsent        = payload.consent_id;
        this.consentDetails.orgName          = (!isEmpty(consentDResponse.ssaDetail) ? consentDResponse.ssaDetail.org_name : null);
        this.consentDetails.creationDateTime = consentDResponse.data.consentBody.data.creationDateTime;

        if (consentDResponse.data.consentBody.data.businessEntity != null){
          
          this.consentDetails.pDocument    = consentDResponse.data.consentBody.data.businessEntity.document.identification;
          //this.consentDetails.pDocument    = this.maskCpfCnpj(consentDResponse.data.consentBody.data.businessEntity.document.identification, consentDResponse.data.consentBody.data.businessEntity.document.identification.length);
          this.consentDetails.pDocumentRel = consentDResponse.data.consentBody.data.businessEntity.document.rel + ':';
        }
        else{
          this.consentDetails.pDocument    = consentDResponse.data.consentBody.data.loggedUser.document.identification;
          this.consentDetails.pDocumentRel = consentDResponse.data.consentBody.data.loggedUser.document.rel + ':';
        }
        this.consentDetails.pValue       = formCurrency.format(consentDResponse.data.consentBody.data.payment.amount);
        
        //this.consentDetails.bCnpj        = this.maskCpfCnpj(consentDResponse.data.consentBody.data.creditor.cpfCnpj, consentDResponse.data.consentBody.data.creditor.cpfCnpj.length);
        this.consentDetails.bCnpj        = consentDResponse.data.consentBody.data.creditor.cpfCnpj;
        this.consentDetails.bName        = consentDResponse.data.consentBody.data.creditor.name;

        if (this.consentDetails.localInstrument == 'MANU'){
          this.consentDetails.bBankCode      = consentDResponse.data.consentBody.data.payment.details.creditorAccount.ispb;
          this.consentDetails.bAgg           = consentDResponse.data.consentBody.data.payment.details.creditorAccount.issuer;
          this.consentDetails.bAccount       = consentDResponse.data.consentBody.data.payment.details.creditorAccount.number;
          this.consentDetails.bAccountType   = this.accountTypes[consentDResponse.data.consentBody.data.payment.details.creditorAccount.accountType];
        }

        this.consentDetails.bPixValue    = consentDResponse.data.consentBody.data.payment.details.proxy;

        //let tempDate = consentDResponse.data.consentBody.data.payment.date.split('-');
        //this.consentDetails.payData      = this.convertUtcDateToString(consentDResponse.data.consentBody.data.payment.date, 'pt-BR', true).replace(' ',' - ');
                
        if (consentDResponse.data.consentBody.data.payment.hasOwnProperty('date')) {
          this.consentDetails.payData = this.convertUtcDateToString(consentDResponse.data.consentBody.data.payment.date, 'pt-BR', false);
          this.consentDetails.payLabel = true;
        } else if (consentDResponse.data.consentBody.data.payment.hasOwnProperty('schedule')) {
          this.consentDetails.payData = this.convertUtcDateToString(consentDResponse.data.consentBody.data.payment.schedule.single.date, 'pt-BR', false);
          this.consentDetails.payLabel = false;
        }
                
        this.consentDetails.payForm = consentDResponse.data.consentBody.data.payment.type;

        //payload.bank_fee = "2.11";
        if (!isEmpty(payload.bank_fee))
          this.consentDetails.payFee       = (isNaN(payload.bank_fee) ? null : formCurrency.format(payload.bank_fee));
        
        let dateTmp = consentDResponse.data.consentBody.data.creationDateTime.split('T')[0].split('-');
        this.consentDetails.multDateTime = dateTmp[2] + '/' + dateTmp[1] + '/' + dateTmp[0] + ' às 23:59';

        this.$root.$refs.loadingModal.disable();
      
      }
    }
    catch (e){
      
      console.log(e);

      this.$root.$refs.loadingModal.disable();
      this.disableAllWarnings();

      if(e == 'Request timed out'){
        this.$refs.fetchtimeoutwarning.enable();
      }
      else if(e == 'invalid consent status'){
        this.$refs.exceptionNotAwaitingAuthorization.enable();
      }
      else{
        this.$refs.exceptionCommunicationFail.enable();
      }

      /*
      let patchToAwaitingResponse = await patchAccount
        ( 'AWAITING_AUTHORISATION', 
        (this.$route.params.hasOwnProperty('data') ? this.$route.params.data : payload.account_id) );
      */
    }
  }
}
</script>

<style scoped>

.bStyle{
  padding: 17px 23px 25px 24px;
  background-color: var(--confcons-page-background-color);
}
.text{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-top: 8px;
}
.weight{
  font-weight: var(--confcons-box2-down-text-Weight);
}
.box2innertext{
  color: var(--confcons-box2-inner-text-color);
}
.box2innertitle{
  color: var(--confcons-box2-inner-title-color);
}
.title{
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  margin-top: 8px;
}
.subtitle{
  margin-top: 8px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: left;
}
.box1{
  background-color: var(--confcons-box1-background-color);
  box-shadow: var(--confcons-box1-shadow);
  padding: 17px;
}
.textbox1{
  color: var(--confcons-box1-text-base-color);
}
.titlebox1{
  color: var(--confcons-box1-text-title-color);
}
.hrbox2{
  color: var(--confcons-box2-up-hrrectangle-color);
  display: var(--confcons-box2-up-hr-display);
}
.box3{
  background-color: var(--confcons-box3-background-color);
  box-shadow: var(--confcons-box3-shadow);
  margin-bottom: 25px; 
  padding: 10px;
}
.titlebox3{
  color: var(--confcons-box3-title-color);
}
.textbox3{
  color: var(--confcons-box3-text-color);
}
.databox3{
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  color: var(--confcons-box3-data-color);
}
hr{
  background-color: var(--confcons-hr-color);
  border: 0;
  clear:both;
  display:block;
  width: 100%;
  height: 0.5px;
  margin-top: 17px;
  margin-bottom: 17px;
}
.editar{
  float: right;
}
#iframeMFA{
  border: none;
  border-radius: 15px;

  width: var(--mfa-width);
  height: var(--mfa-height);
}
</style>